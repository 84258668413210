import React, { useContext } from "react";
import { useDropzone } from "react-dropzone";

import photo from "../../../assets/images/photo.png";
import Button from "../Button";
import { useFolder } from "../../../Store/folder";
import { useUploader } from "../../../Store/upload";
import Text from "../Text";
import { ThemeContext } from "../../App";

const AddImages = ({
  setShowImageUploader = () => {},
  coverImageUpload = false,
  setCurrentView = () => {},
}) => {
  const theme = useContext(ThemeContext);
  const { folders } = useFolder();
  const { onDrop, isUploading, setCoverImageUpload } = useUploader();

  const isDarkMode = theme === "dark";

  const startUpload = (acceptedFiles, rejectedFiles) => {
    if (isUploading) {
      window.Toast.fire({
        icon: "error",
        title: "Please wait for the current upload to finish.",
      });
      return false;
    }
    if (rejectedFiles.length > 0) {
      window.Toast.fire({
        icon: "error",
        title:
          "Some dropped files were rejected as only jpeg images are allowed to be uploaded",
      });
    }
    if (acceptedFiles.length < 1) {
      window.Toast.fire({
        icon: "error",
        title: "No files to upload",
      });
      return false;
    }
    setShowImageUploader(false);
    onDrop(acceptedFiles, folders[folders.length - 1]);
    if (coverImageUpload) {
      setCoverImageUpload(true);
      setCurrentView("onGoingUpload");
    } else {
      setCoverImageUpload(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpg, image/jpeg",
    onDrop: startUpload,
    noClick: false,
    noKeyboard: false,
    multiple: true,
    preventDropOnDocument: false,
    disabled: isUploading,
  });

  return (
    <div className="flex justify-center item-center  flex-col  text-slate-50 mx-4">
      <Text
        as="div"
        className="my-2 text-xs text-dark-gray1 dark:text-light-gray"
      >
        Images you upload will be approved by photographer or host before they
        are available to everyone.
      </Text>
      <div
        {...getRootProps({
          accept: "image/jpg, image/jpeg",
          className: `flex flex-col cursor-pointer mt-6 rounded-lg p-5 justify-center items-center w-full ${
            coverImageUpload
              ? "border border-dashed border-zinc-700 shadow-dark-input"
              : "border border-dashed border-zinc-700 shadow-dark-input"
          } `,
        })}
      >
        <div
          className={`mb-2 ${
            coverImageUpload
              ? "flex flex-col items-center dark:bg-dark-gray dark:text-light-gray text-dark-gray1"
              : ""
          }`}
        >
          <img
            src={photo}
            width="60"
            text-align="center"
            height="60"
            alt="drag &amp; drop your photo"
          />
          {coverImageUpload ? (
            <span className="text-sm">Drag &amp; drop images to upload</span>
          ) : (
            <></>
          )}
          {coverImageUpload ? <span className="text-xs"> OR</span> : <></>}
        </div>
        <div className="text-sm">
          <input {...getInputProps()} />
        </div>
        <Button
          className={`mt-4 text-sm px-6 ${
            coverImageUpload
              ? isDarkMode
                ? "!text-light-gray"
                : "!text-dark-gray1"
              : ""
          }`}
          text="Browse Files"
          bg={
            coverImageUpload
              ? "shadow-button shadow-dark-input-bottom-right dark:shadow-dark-input dark:text-slate-100 text-black  font-semibold text-base"
              : "bg-button"
          }
        />
        <div className="text-xs mt-5 text-zinc-400">
          Maximum upload file size: 30 MB
        </div>
      </div>
    </div>
  );
};

export default AddImages;
