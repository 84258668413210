import qs from "qs";
import EXIF from "exif-js";

import { http } from "./index";
import {
  ENVIRONMENT,
  IMAGE_LIMIT_REACHED,
  MAX_SUB_EVENT_IMAGE_REACHED_ERROR,
} from "../constants";
import axios from "axios";

const HANDLE_DUPLICATES = "skip";

const getPresignedUrl = (
  filename,
  filetype,
  folderId,
  accessToken,
  eventId
) => {
  const data = qs.stringify({
    fileName: filename,
    mimeType: filetype,
    folderId: folderId,
    handleDuplicates: HANDLE_DUPLICATES,
    type: "images",
    accessToken: accessToken,
    eventId: eventId,
  });

  return http.post("guest/presigned-url", data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

function getImageDimensionsWithImage(image) {
  return new Promise(async (res) => {
    const i = new Image();
    i.onload = () => {
      res({
        width: i.width,
        height: i.height,
        img: i,
      });
    };
    i.src = image.preview;
  });
}

function getCanvasBlob(canvas) {
  return new Promise(function (resolve, reject) {
    canvas.toBlob(
      function (blob) {
        resolve(blob);
      },
      "image/jpeg",
      0.95
    );
  });
}

const getImageDimensions = (image) =>
  new Promise(async (res) => {
    const i = new Image();
    i.src = image.preview;
    i.onload = () => {
      res({
        width: i.width,
        height: i.height,
      });
    };
  });

const getExifData = (file) =>
  new Promise((res) => {
    if (file && file.name) {
      EXIF.getData(file, function () {
        var exifData = EXIF.pretty(this);
        if (exifData) {
          res({
            orientation: EXIF.getTag(this, "Orientation") || 1,
            originalDateTime:
              EXIF.getTag(this, "DateTimeOriginal") || file.lastModifiedDate,
          });
        } else {
          res({ orientation: 1, originalDateTime: file.lastModifiedDate });
        }
      });
    }
  });

async function storeResizedImage(
  resizedFile,
  ogFile,
  filePath,
  folderId,
  handleDuplicates,
  user,
  eventId
) {
  let { orientation, originalDateTime } = await getExifData(ogFile);
  let data = qs.stringify({
    fileName: ogFile.name,
    fileSize: (resizedFile.size / Math.pow(1024, 2)).toFixed(2),
    folderId: folderId,
    filePath: filePath,
    handleDuplicates: handleDuplicates,
    "metadata[width]": resizedFile.width,
    "metadata[height]": resizedFile.height,
    "metadata[dateTimeOriginal]": originalDateTime,
    "metadata[orientation]": orientation,
    guestId: user.id,
    eventId: eventId,
    newFaceSearchModel: !!ogFile.newFaceSearchModel,
    collectionId: ogFile.collectionId
  });
  
  return await http.post("guest/folder/upload", data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

const storeImage = async (
  file,
  filePath,
  folderId,
  handleDuplicates,
  user,
  eventId
) => {
  try {
    const { orientation, originalDateTime } = await getExifData(file);
    const data = qs.stringify({
      fileName: file.name,
      fileSize: (file.size / Math.pow(1024, 2)).toFixed(2),
      folderId: folderId,
      filePath: filePath,
      handleDuplicates: handleDuplicates,
      "metadata[width]": file.width,
      "metadata[height]": file.height,
      "metadata[dateTimeOriginal]": originalDateTime,
      "metadata[orientation]": orientation,
      guestId: user.id,
      eventId: eventId,
      newFaceSearchModel: !!file.newFaceSearchModel,
      collectionId: file.collectionId
    });
    return await http.post("guest/folder/upload", data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  } catch (error) {
    throw error;
  }
};

const uploadFailed = (file, index) => {
  file.status = "Failed";
  file.error = "Network Error";
  return { result: false, index: index, file: file };
};

const resizeUploadImages = async (file, index, user, eventId) => {
  return new Promise(function (res) {
    if (file.status === "Uploading" || !file.status) {
      try {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onerror = (error) => {
          console.log(error);
          file.status = "Failed";
          file.error =
            error.currentTarget.error.name === "NotFoundError"
              ? "File Not Found"
              : "File Invalid";
          res(uploadFailed(file, index));
        };
        const startUploadProcess = async () => {
          fileReader.removeEventListener("load", startUploadProcess);

          Object.assign(file, { preview: URL.createObjectURL(file) });
          let { width, height, img } = await getImageDimensionsWithImage(file);
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          const maxWidth = 1600;
          const maxHeight = 1600;

          URL.revokeObjectURL(file.preview);

          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          file.width = width;
          file.height = height;
          try {
            const presignedUrl = await getPresignedUrl(
              file.name,
              file.type,
              file.folderId,
              user.accessToken,
              eventId
            );
            if (presignedUrl.status === 201) {
              const blob = await getCanvasBlob(canvas);

              const resizedFile = new File([blob], file.name, {
                type: blob.type,
              });
              const headers =
                ENVIRONMENT === "staging"
                  ? {
                      "Content-Type": "application/octet-stream",
                    }
                  : {
                      "Content-Type": file,
                      "x-amz-storage-class": "INTELLIGENT_TIERING",
                    };
              const config = {
                method: "put",
                url: presignedUrl.data.preSignedUrl,
                headers: headers,
                data: resizedFile,
              };
              const uploadStatus = await axios(config);
              if (uploadStatus.status === 200) {
                let filePath = presignedUrl.data.destinationUrl;
                resizedFile.width = width;
                resizedFile.height = height;
                const storeResponse = await storeResizedImage(
                  resizedFile,
                  file,
                  filePath,
                  file.folderId,
                  HANDLE_DUPLICATES,
                  user,
                  eventId,
                );
                if (
                  storeResponse.status >= 200 &&
                  storeResponse.status <= 204
                ) {
                  file.status = "Success";
                  file.error = "";
                  file.finalUrl = filePath;
                  storeResponse.data.data.folderId = file.folderId;
                  file.returnData = storeResponse.data.data;
                  res({ result: true, index: index, file: file });
                } else {
                  res(uploadFailed(file, index));
                }
              } else {
                res(uploadFailed(file, index));
              }
            } else {
              res(uploadFailed(file, index));
            }
          } catch (e) {
            console.error(e);
            if (
              e.response.data?.message === MAX_SUB_EVENT_IMAGE_REACHED_ERROR
            ) {
              file.status = "Failed";
              file.error = IMAGE_LIMIT_REACHED;
              res({ result: false, index: index, file: file, error: e });
            } else if (e.message === "canceled") {
              res({ result: false, index: index, file: file, error: e });
            } else res(uploadFailed(file, index));
          }
        };

        fileReader.addEventListener("load", startUploadProcess);
      } catch (error) {
        console.error(error);
        res(uploadFailed(file, index));
      }
    } else {
      res({ result: false, index: index, file: file });
    }
  });
};

const uploadImages = async (file, index, user, eventId) => {
  if (file.status === "Uploading" || !file.status) {
    try {
      const preSignedURL = await getPresignedUrl(
        file.name,
        file.type,
        file.folderId,
        user.accessToken,
        eventId
      );
      if (preSignedURL.status === 201) {
        const headers =
          ENVIRONMENT === "staging"
            ? {
                "Content-Type": "application/octet-stream",
              }
            : {
                "Content-Type": file,
                "x-amz-storage-class": "INTELLIGENT_TIERING",
              };
        const uploadStatus = await http.put(
          preSignedURL.data.preSignedUrl,
          file,
          {
            headers: headers,
          }
        );
        if (uploadStatus.status === 200) {
          Object.assign(file, { preview: URL.createObjectURL(file) });
          let { width, height } = await getImageDimensions(file);
          URL.revokeObjectURL(file.preview);
          file.width = width;
          file.height = height;
          const filePath = preSignedURL.data.destinationUrl;
          const storeResponse = await storeImage(
            file,
            filePath,
            file.folderId,
            HANDLE_DUPLICATES,
            user,
            eventId
          );
          if (storeResponse.status >= 200 && storeResponse.status <= 204) {
            file.status = "Success";
            file.error = "";
            file.finalUrl = filePath;
            storeResponse.data.data.folderId = file.folderId;
            file.returnData = storeResponse.data.data;

            return { result: true, index: index, file: file };
          } else {
            return uploadFailed(file, index);
          }
        } else {
          return uploadFailed(file, index);
        }
      } else {
        return uploadFailed(file, index);
      }
    } catch (e) {
      if (e.response.data?.message === MAX_SUB_EVENT_IMAGE_REACHED_ERROR) {
        file.status = "Failed";
        file.error = IMAGE_LIMIT_REACHED;
        return { result: false, index: index, file: file, error: e };
      } else if (e.message === "canceled") {
        return { result: false, index: index, file: file, error: e };
      } else return uploadFailed(file, index);
    }
  } else return { result: false, index: index, file: file };
};

export { uploadImages, resizeUploadImages };
