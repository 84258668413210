import React, { useEffect, useState } from "react";

import { useEvent } from "../Store/event";
import { UserProvider } from "../Store/user";
import Main from "./common/Main";
import Error from "../pages/Errors";
import Loading from "../pages/Loading";
import { CartProvider } from "../Store/cart";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import {
  PRVACY_POLICY_ROUTE_NAME,
  SUPPORT_POLICY_ROUTE_NAME,
} from "../constants";
import { UploaderProvider } from "../Store/upload";
import CookiePopUp from "./common/CookiePopUp";
import Support from "../pages/Support";

const darkThemeMode = {
  dark: "dark",
  light: "light",
};

export const ThemeContext = React.createContext(darkThemeMode.light);
function App() {
  const eventDetails = useEvent();
  const [themeMode, setThemeMode] = useState(darkThemeMode.light);

  useEffect(() => {
    if (eventDetails.response.data) {
      setThemeMode(eventDetails.response.data.design.backgroundColor);
      document.body.classList.add(
        eventDetails.response.data.design.backgroundColor
      );
    }
  }, [eventDetails]);

  const nonPrivacyPolicyJSX = eventDetails.error ? (
    <Error
      className={themeMode}
      message={eventDetails.response.e.message ?? "Event Not Found"}
      text="Please contact your photographer for more details."
    />
  ) : Object.keys(eventDetails.response).length > 0 ? (
    eventDetails.response.photographer.isActive ? (
      <ThemeContext.Provider value={themeMode}>
        <div className={themeMode}>
          <div className="bg-theme-light dark:bg-dark-gray">
            <UserProvider>
              <CartProvider>
                <UploaderProvider>
                  <Main />
                </UploaderProvider>
                <CookiePopUp></CookiePopUp>
              </CartProvider>
            </UserProvider>
          </div>
        </div>
      </ThemeContext.Provider>
    ) : (
      <Error
        className={themeMode}
        message={"This account is not active"}
        text="Please contact your photographer for more details."
      />
    )
  ) : (
    <Loading className={themeMode} />
  );

  return (
    <div>
      {window.location.pathname === PRVACY_POLICY_ROUTE_NAME ? (
        /** Need to call privacy policy directly because in router we are using google analytics which needs event and in privacy policy page we do not get event due to which error are thrown if we call Main here which calls Router */
        <PrivacyPolicy />
      ) : window.location.pathname === SUPPORT_POLICY_ROUTE_NAME ? (
        <Support />
      ) : (
        nonPrivacyPolicyJSX
      )}
    </div>
  );
}

export default App;
