import React, { useCallback, useEffect, useState } from "react";
import useAsyncQueue from "use-async-queue";
import Swal from "sweetalert2";

import { useEvent } from "./event";
import { resizeUploadImages, uploadImages } from "../apis/upload";
import { getFolderDetails, postVectorInit } from "../apis/events";
import { useUser } from "./user";
import {
  IMAGE_LIMIT_REACHED,
  MAX_SUB_EVENT_IMAGE_REACHED_ERROR,
} from "../constants";

const Uploader = React.createContext();
Uploader.displayName = "Uploader";

const groupBy = (arr, key) => {
  return arr.reduce((result, item) => {
    const groupKey = item[key];
    if (!result[groupKey]) {
      result[groupKey] = [];
    }
    result[groupKey].push(item);
    return result;
  }, {});
};

export const useUploader = () => React.useContext(Uploader);

export const UploaderProvider = ({ children }) => {
  const [showUploader, setShowUploader] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [isRetry, setIsRetry] = useState(false);
  const [duplicates, setDuplicates] = useState("skip");
  const [completedImages, setCompletedImages] = useState([]);
  const [failedImages, setFailedImages] = useState([]);
  const [groupedErrorFiles, setGroupedErrorFiles] = useState([]);
  const [guestUploadedImages, setGuestUploadedImages] = useState([]);
  const [coverImageUpload, setCoverImageUpload] = useState(false);

  const event = useEvent();
  const { user } = useUser();

  const maxUploadSize =
    event.response.photographer?.restrictions?.image_size ?? 25;

  const showMaxImageReachedPopUp = () =>
    Swal.fire({
      title: "Max image uploaded for a sub event",
      text: "Max image has been uploaded. Please create a new sub-event and upload the remaining image in that sub event.",
      icon: "error",
      confirmButtonText: "ok",
    });

  const getGuestUploadImages = async () => {
    const guestId =
      event.response.data.folders[event.response.data.folders.length - 1].id;
    if (!window.location.pathname.includes("facerec")) {
      setTimeout(async () => {
        const data = await getFolderDetails(guestId, user);
        if (!data.error) {
          setGuestUploadedImages(data.response.data);
        }
      }, 1000);
    }
  };

  useEffect(() => {
    if (user.accessToken) {
      getGuestUploadImages();
    }
  }, [user.accessToken]);

  useEffect(() => {
    if (!showUploader) {
      setFilesToUpload([]);
      setCompletedImages([]);
      setFailedImages([]);
      setGroupedErrorFiles([]);
    }
  }, [showUploader]);

  useEffect(() => {
    if (!isRetry) {
      setCompletedImages(filesToUpload.filter((f) => f.status === "Success"));
    }
    setFailedImages(filesToUpload.filter((f) => f.status === "Failed"));
  }, [isRetry, filesToUpload]);

  useEffect(() => {
    if (failedImages.length > 0) {
      let grouped = groupBy(failedImages, "error");
      setGroupedErrorFiles(grouped);
    }
  }, [failedImages]);

  const concurrency = 5;
  const queue = useAsyncQueue({
    concurrency,
  });

  const retryUpload = async (file = {}) => {
    setIsRetry(true);
    setGroupedErrorFiles([]);
    if (file) {
      let newFilestoUpload = filesToUpload.map((ftu) => {
        if (ftu.name === file.name) {
          ftu.error = "";
          ftu.status = "Uploading";
        }
        return ftu;
      });
      setFilesToUpload(newFilestoUpload);
    } else {
      let newFilestoUpload = filesToUpload.map((ftu) => {
        if (
          ftu.error === "Duplicate File" ||
          ftu.error === IMAGE_LIMIT_REACHED
        ) {
        } else if (ftu.size > maxUploadSize * 1024 * 1024) {
          ftu.status = "Failed";
          ftu.error = `Size More Than ${maxUploadSize} MB`;
        } else if (ftu.type !== "image/jpeg" && ftu.type !== "image/jpg") {
          ftu.status = "Failed";
          ftu.error = "File Type Not Allowed";
        } else if (ftu.status === "Failed") {
          ftu.status = "Uploading";
          ftu.duplicate = "replace";
          ftu.error = "";
        }
        return ftu;
      });
      setFilesToUpload(newFilestoUpload);
    }
  };

  const closeUploader = useCallback(() => {
    if (!isUploading) {
      setShowUploader(false);
    }
  }, [isUploading]);

  const onDrop = async (acceptedFiles, guestFolder) => {
    if (showUploader && !isUploading) {
      setShowUploader(false);
    }
    let readyFiles = [];
    let totalSize = 0;
    acceptedFiles.forEach((f) => {
      if (f.status) {
        readyFiles.push(f);
        return f;
      }
      if (f.size > maxUploadSize * 1024 * 1024) {
        f.status = "Failed";
        f.error = `Size More Than ${maxUploadSize} MB`;
      } else if (f.type !== "image/jpeg" && f.type !== "image/jpg") {
        f.status = "Failed";
        f.error = "File Type Not Allowed";
      } else {
        f.status = "Uploading";
        f.error = "";
      }
      f.folderId = guestFolder.id;
      f.newFaceSearchModel = event.response.data.newFaceSearchModel;
      f.collectionId = event.response.data.awsEventName;
      totalSize = totalSize + f.size;
      readyFiles.push(f);
    });

    setDuplicates(duplicates);
    setFilesToUpload(readyFiles);
    setShowUploader(true);
    setIsUploading(true);
  };

  useEffect(() => {
    if (
      completedImages.length + failedImages.length === filesToUpload.length &&
      isUploading
    ) {
      setIsUploading(false);
      // postVectorInit(event.response.data.awsEventName);
    }
  }, [completedImages, failedImages, isUploading]);

  useEffect(() => {
    if (filesToUpload.length > 0) {
      const guestUser = JSON.parse(
        localStorage.getItem("userFor" + event.response.data.id)
      );
      // console.log(filesToUpload);
      filesToUpload.map((f, i) => {
        if (f.status === undefined || f.status === "Uploading") {
          const task = {
            id: i,
            task: () =>
              new Promise(async (resolve) => {
                try {
                  let res = {};
                  if (
                    event.response.photographer?.restrictions?.image_optimize
                  ) {
                    res = await resizeUploadImages(
                      f,
                      i,
                      guestUser,
                      event.response.data.id
                    );
                  } else {
                    res = await uploadImages(
                      f,
                      i,
                      guestUser,
                      event.response.data.id
                    );
                  }
                  if (res.result && res.file.status === "Success") {
                    setCompletedImages((prev) => [
                      ...prev,
                      res.file.returnData,
                    ]);
                    setGuestUploadedImages((prev) => [
                      ...prev,
                      res.file.returnData,
                    ]);
                  } else {
                    if (
                      res.error?.response?.data?.message ===
                      MAX_SUB_EVENT_IMAGE_REACHED_ERROR
                    ) {
                      showMaxImageReachedPopUp();
                    }
                    setFailedImages((prev) => [...prev, res.file]);
                  }
                  resolve(res.file);
                } catch (error) {
                  console.log(error);
                }
              }),
          };
          queue.add(task);
        }
      });
    }
  }, [filesToUpload]);

  return (
    <Uploader.Provider
      value={{
        onDrop,
        retryUpload,
        showUploader,
        setShowUploader,
        isUploading,
        filesToUpload,
        completedImages,
        failedImages,
        groupedErrorFiles,
        closeUploader,
        duplicates,
        guestUploadedImages,
        setGuestUploadedImages,
        coverImageUpload,
        setCoverImageUpload,
      }}
    >
      {children}
    </Uploader.Provider>
  );
};
