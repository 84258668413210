import React, { useEffect, useState } from "react";
import { useContext } from "react";

import { REDIRECT_ON_CART_USER } from "../../../constants";
import Loading from "../../../pages/Loading";
import { useCart } from "../../../Store/cart";
import { useEvent } from "../../../Store/event";
import { useUploader } from "../../../Store/upload";
import { useUser } from "../../../Store/user";
import { ThemeContext } from "../../App";
import BottomUploadModal from "../BottomUploadModal";
import Router from "../Router";

function Main() {
  const { isUploading, showUploader } = useUploader();
  const { user, requestOnGoing } = useUser();
  const themeMode = useContext(ThemeContext);
  const { cartApiCalled } = useCart();
  const eventDetails = useEvent().response;

  const [showLoader, setShowLoader] = useState(
    REDIRECT_ON_CART_USER.includes(eventDetails.photographer.id) ||
      (window.location.pathname.includes("add-to-cart") && !cartApiCalled)
  );

  const alertUser = (e) => {
    if (isUploading) {
      e.preventDefault();
      e.returnValue = "";
    } else {
      return true;
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, [isUploading]);

  useEffect(() => {
    if (
      REDIRECT_ON_CART_USER.includes(eventDetails.photographer.id) ||
      (window.location.pathname.includes("add-to-cart") && !cartApiCalled)
    ) {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const accessToken = params.get("at");
      if (accessToken) setShowLoader(true);
      else setShowLoader(false);
    } else setShowLoader(false);
  }, []);

  useEffect(() => {
    if (user.email && cartApiCalled) setShowLoader(false);
  }, [user, cartApiCalled]);

  return showLoader ? (
    <Loading className={themeMode} />
  ) : (
    <div>
      {requestOnGoing ? <Loading className={themeMode} /> : <Router />}
      {showUploader ? <BottomUploadModal /> : <></>}
    </div>
  );
}

export default Main;
