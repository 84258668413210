import axios from "axios";
import Cookies from "js-cookie";

import { LOCALSTORAGE_ACCESS_TOKEN } from "../constants";
import { http } from "./index";

const endpoint = process.env.REACT_APP_API_ENDPOINT;

const sendResponse = (result) => {
  if (result.status === 200 || result.status === 204 || result.status === 201) {
    return { error: false, response: result.data };
  } else {
    return {
      error: true,
      message: result.message
        ? result.message
        : "Couldnot Complete Your Request",
      e: result.error ? result.error : "Something Went Wrong",
    };
  }
};

//API Call to get event details
export const getEventDetails = async () => {
  try {
    let url = window.location.href
      .split("?")[0]
      .split("#")[0]
      .replace(/\/$/, "");
    let urlObj = new URL(url);
    let pathArray = urlObj.pathname.split("/");
    const jwt = Cookies.get(LOCALSTORAGE_ACCESS_TOKEN);
    if (pathArray[3])
      url = `${urlObj.origin}/${pathArray[1]}/${pathArray[2]}/${pathArray[3]}`;
    else if (!pathArray[3] && pathArray[2])
      url = `${urlObj.origin}/${pathArray[1]}/${pathArray[2]}`;
    else url = `${urlObj.origin}/${pathArray[1]}`;
    if (
      url.includes("favourites") &&
      window.location.href.includes("searchId")
    ) {
      url = url.replace("/favourites/", "/facerec/");
    } else if (url.includes("favourites")) {
      url = url.replace("/favourites/", "/view/");
    } else if (url.includes("add-to-cart") || url.includes("checkout")) {
      url = url.replace("/view/", "/facerec/");
    }
    url = url.replace("/complete-shopping/", "/view/");
    url = url.replace("/clientlogin/", "/view/");
    url = url.replace("/downloadNow/", "/facerec/");
    url = url.replace(
      "http://192.168.1.103:3000/",
      "https://reelsandframes.algomage.com/"
    );
    url = url.replace(
      "http://localhost:3000/",
      "https://reelsandframes.algomage.com/"
    );
    url = url.replace(
      "http://localhost:3005/",
      "https://etherealstudioin.algomage.com/"
    );
    url = url.replace("http://localhost:3006/", "https://admins.algomage.com/");
    url = url.replace("algomage.ai", "algomage.com");
    url = url.toLocaleLowerCase();
    let x = await axios.get(`https://country.pixnif.com/`);
    let result = await axios.get(`${endpoint}events/details?url=` + url, {
      headers: {
        Accept: "application/json",
        "X-XSRF-TOKEN": "{{ xsrf-token }}",
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${jwt}`,
      },
    });
    result.data.country = x.data.country.name;
    return sendResponse(result);
  } catch (e) {
    console.error(e);
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

//API call to get images of individual folders
export const getFolderDetails = async (
  folderId,
  user,
  sortCond = "asc",
  sortField = "image_date",
  signal = null,
  pageNumber = 1
) => {
  let params = { folderId: folderId };
  if (user.accessToken) params.accessToken = user.accessToken;
  if (user.password) params.password = user.password;
  params.sortField = sortField;
  params.sortCond = sortCond;
  params.page = pageNumber;
  try {
    let result = await axios.get(`${endpoint}folder/images`, {
      params: params,
      headers: {
        Accept: "application/json",
        "X-XSRF-TOKEN": "{{ xsrf-token }}",
        "Content-Type": "application/x-www-form-urlencoded",
        ...(!user.accessToken && { Authorization: `Bearer ${user.jwt}` }),
      },
      signal: signal,
    });
    return sendResponse(result);
  } catch (e) {
    // console.log(e.message)
    if (e.message === "canceled") {
      throw new Error(e);
    } else
      return sendResponse({
        status: e.response.status,
        message: e.response.data.message,
        error: e.response.data.errors,
      });
  }
};

//API call to login into the event
//Or to get the access token
export const guestLogin = async (eventId, email = "", password = "") => {
  let user = JSON.parse(localStorage.getItem("userFor" + eventId));
  let FormData = require("form-data");
  let data = new FormData();
  data.append("eventId", eventId);
  if (email) data.append("email", email);
  if (password) data.append("password", password);
  let config = {
    method: "post",
    url: `${endpoint}guest/event/login`,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + user.jwt,
    },
    data: data,
  };
  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const guestDetails = (accessToken) => {
  return http.get(`guest-details?accessToken=${accessToken}`);
};

export const validatePin = async (eventId, pin) => {
  var data = new FormData();
  data.append("eventId", eventId);
  data.append("pin", pin);

  var config = {
    method: "post",
    url: `${endpoint}facerec/login`,
    data: data,
  };

  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const loginFaceReg = async (eventId, user) => {
  var data = new FormData();
  data.append("eventId", eventId);
  data.append("firstName", user.fname);
  data.append("lastName", user.lname);
  data.append("email", user.email);
  if (![null, "", undefined].includes(user.phone)) {
    if (!user.phone.includes("+")) {
      data.append("phone", "+" + user.phone);
    } else data.append("phone", user.phone);
  }
  data.append("pin", user.pin);
  data.append("photographerId", user.photographerId);

  var config = {
    method: "post",
    url: `${endpoint}facerec/register`,
    data: data,
  };

  try {
    var result = await axios(config);
    return sendResponse(result);
  } catch (e) {
    return sendResponse({
      status: e.response.status,
      message: e.response.data.message,
      error: e.response.data.errors,
    });
  }
};

export const postVectorInit = (eventName) =>
  http.post("refresh-event", null, { params: { awsEventName: eventName } });

export const getPhotographerDetails = () => {
  const jwt = Cookies.get(LOCALSTORAGE_ACCESS_TOKEN);
  return axios.get(`${endpoint}user/gallery/details`, {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  });
};

export const postUpdateGuest = (eventId, newUser, user) => {
  var data = new FormData();
  data.append("eventId", eventId);
  data.append("firstName", newUser.fname);
  data.append("lastName", newUser.lname);
  data.append("accessToken", user.accessToken);
  ![null, "", undefined].includes(newUser.phone) &&
    !newUser.phone.includes("+") &&
    data.append("phone", "+" + newUser.phone);

  return axios.post(`${endpoint}guest/update-name`, data);
};

export const getSponsorDetails = (eventId) =>
  axios.get(`${endpoint}sponsor/details`, { params: { eventId } });

export const getFacePatch = (collectionId, page = 0) => {
  return http.get("facepatches", { params: { collectionId, page } });
};

export const getFacePatchName = (collectionId) => {
  return http.get("facepatches-name", { params: { collectionId } });
};

export const postFacePatchName = (collectionId, postData) => {
  return http.post("facepatch-name", { collectionId, postData });
};

export const getFacePatchResult = async (collectionId, faceId, page) => {
  return http.get("facepatch-images", {
    params: { collectionId, faceId, page },
  });
};

export const postFacePatchSearchId = async (payload) => {
  return http.post("save-face-search", payload);
};

export const postSendEmail = async (payload) =>
  http.post("send-fp-email", payload);
